import React from 'react'
import Link from 'gatsby-link'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
import Layout from '../layouts/index'

class Intro extends React.Component {
  constructor() {
    super()

    this.state = {
      current: 0,
      total: 5,
    }
  }

  componentDidMount() {
    this.interval = setInterval(this.rotate, 4000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  rotate = () => {
    let next = this.state.current + 1

    if (next >= this.state.total) {
      next = 0
    }

    this.setState({ current: next })
  }

  render() {
    const { current } = this.state

    const lines = [
      'innovative',
      'elegant',
      'ground-breaking',
      'mobile-friendly',
      'Magento 2',
    ]

    return (
      <div className="pagehead">
        <div
          className="pagehead__wrap black"
          style={{ backgroundImage: "url('pageheader2.jpg')" }}
        >
          <div className="intro">
            <div className="small-12 columns">
              <header className="text-center">
                <h1>
                  <div className="line first">We create</div>
                  <div className="line">
                    {lines.map((line, idx) => (
                      <span
                        key={idx}
                        className={
                          'popout' + (current === idx ? ' visible' : '')
                        }
                      >
                        <b>{line}</b>
                      </span>
                    ))}
                  </div>
                  <div className="line last">online stores</div>
                </h1>
              </header>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

class Index extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const blogPosts = get(this, 'props.data.allMarkdownRemark.edges')

    return (
      <Layout location={this.props.location}>
        <div>
          <Helmet title={siteTitle}>
            <meta
              name="description"
              content="Full-service eCommerce agency. Magento2 - we do it!"
            />
          </Helmet>

          <Intro />

          <div className="callout magento narrow">
            <div className="grid-x one-line">
              <div className="cell text-center medium-12 large-8 large-offset-1 small-12">
                <h4>
                  Ready to migrate your store to <b>Magento 2</b>? So we are!
                </h4>
              </div>
              <div className="cell text-center medium-12 large-2 end small-12">
                <Link
                  className="button hollow"
                  to="/why-magento-2"
                  title="Magento 2: why should you consider it?"
                >
                  Learn more
                </Link>
              </div>
            </div>
          </div>

          <div className="callout white">
            <div className="grid-x">
              <div className="cell small-10 small-offset-1 medium-8 medium-offset-2 text-center">
                <h2>Lift Your Brand Up</h2>
                <h4>
                  Mobelop is an agile eCommerce agency based in Slovakia and
                  Ukraine
                </h4>
                <p>
                  Our goal is to help you optimize your online business and sell
                  more products
                </p>
              </div>
            </div>
          </div>

          <div className="callout blue">
            <div className="grid-x">
              <div className="cell text-center small-10 small-offset-1 medium-6 medium-offset-3">
                <h5>
                  <strong>
                    Have a project you are interested in discussing with us?
                    <br />
                    Drop us a line, we'd love to talk!
                  </strong>
                </h5>
                <Link className="button hollow large" to="/contact">
                  Contact us
                </Link>
              </div>
            </div>
          </div>

          <div className="callout white">
            <div className="grid-x">
              <div className="cell medium-10 medium-offset-1">
                <div className="grid-x">
                  {blogPosts.map((edge, idx) => {
                    const node = edge.node

                    const thumbnail = node.frontmatter.thumbnail ? (
                      <GatsbyImage
                        image={
                          node.frontmatter.thumbnail.childImageSharp
                            .gatsbyImageData
                        }
                      />
                    ) : (
                      // <Img
                      //   fluid={node.frontmatter.thumbnail.childImageSharp.fluid}
                      //   fadeIn={true}
                      // />
                      <p></p>
                    )

                    return (
                      <div
                        key={idx}
                        className="cell medium-3 medium-offset-1 small-10 small-offset-1"
                      >
                        <Link to={node.fields.slug}>{thumbnail}</Link>
                        <Link to={node.fields.slug}>
                          {node.frontmatter.title}
                        </Link>
                        <br />
                        <small>{node.frontmatter.date}</small>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Index

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
      }
    }

    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 3
    ) {
      edges {
        node {
          excerpt

          fields {
            slug
          }

          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            title
            author

            thumbnail {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 128)
              }
            }
          }
        }
      }
    }
  }
`
